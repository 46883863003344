import React from 'react';
import { Box, Center, Heading, Text, Divider, Image, LinkOverlay, LinkBox } from '@chakra-ui/react';
import banner from '../img/launch_logo.png'

const LandingPage = () => {
  return (
    <Box p={6} w="100%">
        <Center>
      <Heading as="h1" size="2xl" mb={4} color="RGBA(0, 0, 0, 0.64)" textAlign="center" w="100%">
        Welcome to Snap Food Recipes!
      </Heading>
      </Center>
      <Divider mt={10} mb={10}/>
      <Center>
        <LinkBox w='50%'>
        <LinkOverlay href="https://apps.apple.com/gb/app/snap-food-recipes/id6477355498">
      <Image src={banner} alt="banner"/>
      </LinkOverlay>
      </LinkBox>
      </Center>
      </Box>
  );
};

export default LandingPage;
