import React, {useState} from 'react';
import { Box, Heading, FormControl, FormLabel, Input, Textarea, Button, Center, Divider } from '@chakra-ui/react';

const ContactForm = () => {
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [buttonText, setButtonText] = useState("Submit");

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true)
    setTimeout(() => {
        setLoading(false);
        setDisabled(true)
        setButtonText("Done!")
      }, 2000);
  };

  

  return (
    <Box p={6} w="100vw">
        <Center>
      <Heading as="h1" size="2xl" mb={10} color="RGBA(0, 0, 0, 0.64)">
        Contact Snap Food
      </Heading>
      </Center>
      <Divider mb="10"/>
      <Center>
      <form onSubmit={handleSubmit}>
        <FormControl id="name" mb={4} w="450px">
          <FormLabel>Name</FormLabel>
          <Input type="text" />
        </FormControl>
        <FormControl id="email" mb={4}>
          <FormLabel>Email</FormLabel>
          <Input type="email" />
        </FormControl>
        <FormControl id="message" mb={4}>
          <FormLabel>Message</FormLabel>
          <Textarea />
        </FormControl>
          <Button type="submit" isLoading={loading} isDisabled={disabled} colorScheme="blue">
            {buttonText}
          </Button>
      </form>
      </Center>
    </Box>
  );
};

export default ContactForm;
