import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ChakraProvider, Spacer, VStack } from "@chakra-ui/react";
import App from "./App";
import Footer from "./Components/Footer";
import BackgroundEffect from "./Components/Background";
import PrivacyPolicy from "./Components/Privacy";
import ContactForm from "./Components/Contact";

const root = ReactDOM.createRoot(document.getElementById("root"));

const Routing = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" exact element={<App />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<ContactForm />} />
        </Routes>
      </Router>
    </>
  );
};

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <BackgroundEffect />
      <VStack minH="100vh" minW="100vw" spacing="0">
        <Routing />
        <Spacer />
        <Footer />
      </VStack>
    </ChakraProvider>
  </React.StrictMode>
);
