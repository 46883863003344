import React from 'react';
import styled from 'styled-components';

const BackgroundEffect = () => {
  const gradient = 'radial-gradient(circle at bottom right, #a4cb45, #2fc467, #0295da)';

  const Background = styled.div`
    background: ${gradient};
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  `;

  return <Background />;
};

export default BackgroundEffect;
