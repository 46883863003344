import {
  Box,
  Container,
  Image,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import logo from "../img/Snap Food Logo.png";

export default function Footer() {
  function getYear() {
    return new Date().getFullYear();
  }
  return (
    <Box bg="white" color="black" w="100%" opacity="0.5">
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        direction={{ base: "column", md: "row" }}
        spacing={4}
        justify={{ base: "center", md: "space-between" }}
        align={{ base: "center", md: "center" }}
      >
        <Image src={logo} alt="Logo" h={10} />
        <Text>© {getYear()} Alex Powell. All rights reserved</Text>
        <Stack direction={"row"} spacing={6}></Stack>
      </Container>
    </Box>
  );
}
