import React from 'react';
import { Box, Center, Divider, Heading, Text } from '@chakra-ui/react';

const PrivacyPolicy = () => {
  return (
    <Box p={6} w="100%">
        <Center>
      <Heading as="h1" size="2xl" mb={4} color="RGBA(0, 0, 0, 0.64)">
        Snap Foods Privacy Policy
      </Heading>
      </Center>
      <Divider mt={10} mb={10}/>
      <Text as="b">Last Updated: 5th Feb 2024</Text>

      <Text mt={4}>
        Thank you for using Snap Foods Recipe App. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our mobile application.
      </Text>

      <Heading as="h2" size="md" mt={6}>
        1. Information We Collect
      </Heading>
      <Text>
        <strong>1.1 Personal Information</strong>
        <br />
        We may collect personal information that you provide to us when using Snap Foods Recipe App, such as your name, email address, and any other information you voluntarily submit.
      </Text>
      <Text>
        <strong>1.2 Non-Personal Information</strong>
        <br />
        We may also collect non-personal information, such as device information, operating system, and usage data. This information helps us improve our app and provide a better user experience.
      </Text>

      <Heading as="h2" size="md" mt={6}>
        2. How We Use Your Information
      </Heading>
      <Text>
        <strong>2.1 Providing Services</strong>
        <br />
        We use the information collected to provide and improve Snap Foods Recipe App services, including personalized recipe recommendations, updates, and customer support.
      </Text>
      <Text>
        <strong>2.2 Analytics</strong>
        <br />
        Non-personal information is used for analytics purposes to understand user behavior and enhance our app's features and functionality.
      </Text>

      <Heading as="h2" size="md" mt={6}>
        3. Data Security
      </Heading>
      <Text>
        We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, and destruction. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure.
      </Text>

      <Heading as="h2" size="md" mt={6}>
        4. Third-Party Services
      </Heading>
      <Text>
        Snap Foods Recipe App may use third-party services (e.g., analytics tools) that may collect information as well. These services have their own privacy policies, and we recommend reviewing them for a comprehensive understanding.
      </Text>

      <Heading as="h2" size="md" mt={6}>
        5. Children's Privacy
      </Heading>
      <Text>
        Snap Foods Recipe App is not intended for users under the age of 13. We do not knowingly collect personal information from children. If you believe a child has provided us with personal information, please contact us, and we will take steps to delete such information.
      </Text>

      <Heading as="h2" size="md" mt={6}>
        6. Changes to Privacy Policy
      </Heading>
      <Text>
        We may update this Privacy Policy from time to time. Any changes will be reflected on this page, and the date of the latest revision will be indicated at the top. We encourage you to review this Privacy Policy periodically.
      </Text>

      <Heading as="h2" size="md" mt={6}>
        7. Contact Us
      </Heading>
      <Text>
        If you have any questions or concerns about this Privacy Policy or the use of your information, please contact us at <strong>contact@email.com</strong>.
      </Text>

      <Text mt={4}>
        By using Snap Foods Recipe App, you agree to the terms outlined in this Privacy Policy. Thank you for trusting Snap Foods Recipe App with your information!
      </Text>
    </Box>
  );
};

export default PrivacyPolicy;
